import React, { useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, AppBar, Toolbar, TextField, Box } from "@material-ui/core";
import TalismaLogo from "assets/images/logo.png";
import usersService from "services/usersService";

import {
  Container,
  ContentToolbar,
  Divider,
  FormContainer,
  Logo,
  LogoContainer,
} from "./style";
import Button from "components/Button";
import { useQuery } from "config/useQuery";
import history from "config/history";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import { InputAdornment, IconButton } from "@material-ui/core";

function NewPassword() {
  const query = useQuery();
  const formEl = useRef(null);
  const [token] = useState(query.get("token") || "");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorPasswords, setErrorPasswords] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validadeCaract, setValidadeCaract] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  function handleSubmit() {
    let data = {
      password: newPassword,
    };

    if (password) {
      setErrorPassword(false);
    } else {
      setErrorPassword(true);
    }

    if (newPassword) {
      setErrorNewPassword(false);
    } else {
      setErrorNewPassword(true);
    }

    if (password && newPassword) {
      if (newPassword.length > 7) {
        setValidadeCaract(false);
      } else {
        setValidadeCaract(true);
      }

      if (password === newPassword && newPassword.length > 7) {
        setErrorPasswords(false);

        usersService.changePassword(data, token).then(() => {
          setErrorPassword("");
          toast.success("Senha alterada com sucesso!");
          history.push("/");
        });
      } else {
        setErrorPasswords(true);
      }
    }
  }

  return (
    <>
      <ContentToolbar>
        <AppBar position="fixed" style={{ background: "primary" }}>
          <Toolbar>
            <Typography variant="h6"></Typography>
          </Toolbar>
          <Divider />
        </AppBar>
      </ContentToolbar>
      <Container>
        <LogoContainer>
          <Logo src={TalismaLogo} />
        </LogoContainer>
        <FormContainer ref={formEl} noValidate autoComplete={"off"}>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginBottom: "20px" }}
            color="textSecondary"
          >
            Digite sua nova senha
          </Typography>
          <Box mb={2} style={{ marginBottom: 20 }}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Nova senha
            </Typography>
            <TextField
              name="password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              placeholder="Nova senha"
              style={{ marginTop: "5px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errorPassword}
              helperText={errorPassword ? "Campo obrigatório!" : ""}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityOutlined color="primary" />
                      ) : (
                        <VisibilityOffOutlined color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mb={2} style={{ marginBottom: 20 }}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Confirmar nova senha
            </Typography>
            <TextField
              name="confirm-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              placeholder="Confirmar nova senha"
              style={{ marginTop: "5px" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={errorNewPassword}
              helperText={
                validadeCaract
                  ? "Senha deve conter no minimo 8 caracteres"
                  : errorPasswords
                  ? "Senhas devem ser iguais"
                  : errorNewPassword
                  ? "Campo obrigatório!"
                  : ""
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityOutlined color="primary" />
                      ) : (
                        <VisibilityOffOutlined color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Button
            title={"Enviar"}
            color={"primary"}
            width={90}
            handleOnClick={handleSubmit}
          />
          <div className="collum-center-width">
            <Typography
              variant="h5"
              style={{ marginTop: "30px" }}
              color="textSecondary"
            >
              Lembrou sua senha?{" "}
              <Link to="/" className="link-login">
                Login
              </Link>
            </Typography>
          </div>
        </FormContainer>
      </Container>
    </>
  );
}

export default withRouter(NewPassword);
