import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Drawer, Divider, Collapse, Box, Dialog, DialogTitle, DialogContent, DialogContentText } from "@material-ui/core";
import { ArrowForwardIos, ExpandMore, ExpandLess, ExitToAppTwoTone, ArrowForward, AssessmentOutlined} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { SvgIcon } from "@material-ui/core";
import Api from "config/api";

import { ReactComponent as iconProduct } from "assets/icons/product.svg";
import { ReactComponent as iconOrder } from "assets/icons/order.svg";
import { ReactComponent as iconClient } from "assets/icons/client.svg";
import { ReactComponent as iconStock } from "assets/icons/stock.svg";
import { ReactComponent as iconCupom } from "assets/icons/cupom.svg";
import { ReactComponent as iconCollaborators } from "assets/icons/users.svg";
import { ReactComponent as iconCallcenter } from "assets/icons/callcenter.svg";
import { ReactComponent as iconStroke } from "assets/icons/stroke.svg";
import { ReactComponent as circle } from "assets/icons/circle.svg";

import { ButtonContainer, Icon, Label, ListContainer, Logo, LogoContainer, MenuItem, SubItem } from "./styles";
import { Button } from "assets/styles/styles";

import TalismaLogo from "assets/images/logo2.png";
import TalismaLogoIcon from "assets/images/logoIcon.png";
import { Creators } from "store/ducks/auth";
import usersService from "services/usersService";
import Select from "components/Select";
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  menuClose: {
    width: "5rem",
    minWidth: "5rem",
    maxWidth: "5rem",
  },
  menuOpen: {
    minWidth: "16rem",
    width: "16rem",
  },
  listItem: {
    display: "flex",
    flex: "column",
  },
}));

function Menu({ open, setOpen }) {
  const [menuItens, setMenuItens] = useState([]);

  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportType, setReportType] = useState("ENTRY_PRODUCTS")
  const [periodType, setPeriodType] = useState("DAILY")
  const [isDownloadingReport, setIsDownlaodingReport] = useState(false)

  const pathnamePage = window.location.pathname;
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    
    usersService.getOneUsers(userId)
      .then((response) => {
        setName(response.name);
        setAvatar(response.avatar?.url);
      })
  }, []);

  const userId = localStorage.getItem("userId");

  async function loadVerifyUser() {
    try {
      const { data } = await Api.get(`/users/${userId}`);
      console.log(data.name);
    } catch (err) {
      console.log(err.response.status);

      if (err.response.status === 401) {
        localStorage.clear();
        dispatch(Creators.signOut()); 
      }
    }
  }

  useEffect(() => {
    loadVerifyUser();
  }, []);

  useEffect(() => {
    setMenuItens([
      {
        label: "Pedidos",
        path: "/pedidos",
        key: "pedidos",
        icon: <SvgIcon component={iconOrder} viewBox="0 0 22 28" />,
        subItem: [
          {
            label: "Pedidos",
            path: "/pedidos/pedidos",
            key: "pedidos-pedidos",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Tabela de fretes",
            path: "/pedidos/tabela-de-fretes",
            key: "tabela-de-fretes",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Formas de pagamento",
            path: "/pedidos/formas-de-pagamento",
            key: "pedidos-novidades",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Configurações",
            path: "/pedidos/configuracoes",
            key: "configuracoes",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("pedidos"),
      },
      {
        label: "Produtos",
        path: "/produtos",
        key: "produto",
        icon: <SvgIcon component={iconProduct} viewBox="0 0 24 28" />,
        subItem: [
          {
            label: "Produtos",
            path: "/produtos",
            key: "produtos-produtos",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Promoções",
            path: "/produto/promocoes",
            key: "produtos-promocoes",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Novidades",
            path: "/produto/novidades",
            key: "produtos-novidades",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Categorias",
            path: "/produto/categorias",
            key: "produtos-ategorias",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Tipos de produto",
            path: "/produto/tipos",
            key: "tipos-produto",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("produto"),
      },
      {
        label: "Estoque",
        path: "/estoque",
        key: "estoque",
        icon: <SvgIcon component={iconStock} viewBox="0 0 22 28" />,
        subItem: [
          {
            label: "Consulta",
            path: "/estoque",
            key: "estoque-estoque",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Lançamento",
            path: "/lancamento-estoque",
            key: "estoque-lancamento",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("estoque"),
      },
      {
        label: "Usuários",
        path: "/usuarios",
        key: "cliente",
        icon: <SvgIcon component={iconClient} viewBox="0 0 24 28" />,
        subItem: [
          {
            label: "Usuários",
            path: "/usuarios",
            key: "clientes-clientes",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Tipos de Usuários",
            path: "/tipos-usuarios",
            key: "tipos-cliente",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("usuario"),
      },
      {
        label: "Cupons",
        path: "/cupons",
        key: "cupons",
        icon: <SvgIcon component={iconCupom} viewBox="0 0 22 28" />,
      },
      {
        label: "Colaboradores",
        path: "/colaboradores",
        key: "colaboradores",
        icon: <SvgIcon component={iconCollaborators} viewBox="0 0 22 28" />,
      },
      {
        label: "Atendimento",
        path: "/atendimento",
        key: "atendimentos",
        icon: <SvgIcon component={iconCallcenter} viewBox="0 0 24 28" />,
        subItem: [
          {
            label: "FAQ",
            path: "/atendimento/faq",
            key: "faq",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Config. WhatsApp",
            path: "/atendimento/config-whatsapp",
            key: "config-whatsapp",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Notificações",
            path: "/atendimento/notificacoes",
            key: "atendimento-notificacoes",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("atendimento"),
      },
      {
        label: "Permissões",
        path: "/permissoes",
        key: "permissoes",
        icon: <SvgIcon component={iconStroke} viewBox="0 0 24 28" />,
        subItem: [
          {
            label: "Controle de acesso",
            path: "/permissoes/controle-de-acesso",
            key: "controle-de-acesso",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
          {
            label: "Auditorias",
            path: "/permissoes/auditorias",
            key: "auditorias",
            icon: <SvgIcon component={circle} viewBox="0 0 30 30" />,
          },
        ],
        expanded: pathnamePage.includes("permissoes"),
      },
    ]);

    return () => {
      setMenuItens([]);
    };
  }, [pathnamePage]);

  const handleMenuClick = (subItem) => {
    const items = menuItens.map((item) => {
      if (item.key === subItem.key) {
        item.expanded = !item.expanded;
      }
      return item;
    });
    setMenuItens(items);
    if (!open) {
      setOpen();
    }
  };


  const downloadReport = async () => {
    const reportData = {
      reportType: reportType,
      periodType: periodType,
    };

    try {
      setIsDownlaodingReport(true);
      const response = await Api.post('/reports', reportData, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
  
      link.download = `Relatorio_${getReportTitleByType(reportData.reportType)}_${new Date().toISOString()}.xlsx`;
      link.click();

      window.URL.revokeObjectURL(url);
      setIsDownlaodingReport(false)
    } catch (error) {
      setIsDownlaodingReport(false)
      console.error('Erro ao baixar o relatório:', error);
      alert('Ocorreu um erro ao tentar gerar o relatório.');
    }
  };

  const getReportTitleByType = (reportType) => {
    if (reportType == 'CLIENTS') return 'Clientes';
  
    if (reportType == 'INVENTORY') return 'Inventario';
  
    if (reportType == 'ENTRY_PRODUCTS') return 'Entradas';

    if (reportType == 'SALES') return 'Vendas';
  };
  
  return (
    <>
      <Dialog open={showReportModal}>
        <DialogTitle>Geração de relatórios</DialogTitle>
        <DialogContent>
          <div style={{ paddingBottom: 10 }}>
            <DialogContentText>
              A geração do relatório pode levar alguns minutos, dependendo do tipo e do periodo.
            </DialogContentText>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <Select
                  disabled={isDownloadingReport}
                  offLabelSelect
                  label="Tipo de relatório"
                  listOption={[
                    { id: "ENTRY_PRODUCTS", name: "Entradas" },
                    { id: "SALES", name: "Vendas" },
                    { id: "INVENTORY", name: "Inventario" },
                    { id: "CLIENTS", name: "Clientes" },
                  ]}
                  onChange={(e) => setReportType(e.target.value)}
                  value={reportType}
                />
                <Select
                  disabled={isDownloadingReport}
                  offLabelSelect
                  label="Período"
                  listOption={[
                    { id: "DAILY", name: "Diário" },
                    { id: "WEEKLY", name: "Semanal" },
                    { id: "MONTHLY", name: "Mensal" },
                  ]}
                  onChange={(e) => setPeriodType(e.target.value)}
                  value={periodType}
                />
                <div style={{ display: "flex", gap: 8, justifyContent:"flex-end" }}>
                  <Button
                    type="button"
                    typeButton="delete"
                    disabled={isDownloadingReport}
                    onClick={() => setShowReportModal(false)}
                    >
                      Fechar
                  </Button>
                  <Button
                    type="button"
                    typeButton="export"
                    disabled={isDownloadingReport}
                    onClick={() => downloadReport()}
                    style={{ minWidth: 120 }}
                  >
                    {isDownloadingReport ? <CircularProgress color="inherit" size="20px" /> : "Baixar"}
                  </Button>
                </div>
              </div>
            </div>
        </DialogContent>
      </Dialog>
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.menuOpen]: open,
        [classes.menuClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.menuOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <LogoContainer>
        <Logo src={open ? TalismaLogo : TalismaLogoIcon} open={open} />
      </LogoContainer>
      <ButtonContainer>
        <Icon onClick={setOpen} open={open}>
          {open ? (
            <SvgIcon viewBox="0 0 16 24">
              <path d="M11.67 3.87 9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z" />
            </SvgIcon>
          ) : (
            <ArrowForwardIos />
          )}
        </Icon>
      </ButtonContainer>
      <Divider variant={"middle"} />
      <ListContainer open={open}>
        <MenuItem component={Link} to={"/perfil"} button>
          {open ? (
            <div className="row-center-width" style={{ margin: "5px 0" }}>
              <img
                className="image-perfil-circle"
                src={ avatar ? avatar : "https://tm.ibxk.com.br/2017/06/22/22100428046161.jpg?ims=1200x675" }
                alt="Imagem do perfil"
              />
              <span style={{ marginLeft: 15, textTransform: "capitalize" }}>{name.split(" ")[0]} {name.split(" ")[1]}</span>
              <div>
                <ArrowForward style={{ fontSize: 22 }} />
              </div>
            </div>
          ) : (
            <div className="row-center-width" style={{ margin: "5px 0", justifyContent: 'center' }}>
              <img
                className="image-perfil-circle"
                src={ avatar ? avatar : "https://tm.ibxk.com.br/2017/06/22/22100428046161.jpg?ims=1200x675" }
                alt="Imagem do perfil"
              />
            </div>
          )}
        </MenuItem>

        {menuItens.map((item) => (
          <div key={item.key}>
            {!item.subItem ? (
              <MenuItem
                component={Link}
                to={item.path}
                button
                open={open}
                key={item.path + item.key}
                selected={pathnamePage.includes(item.key)}
              >
                {item.icon}
                {open && <span>{item.label}</span>}
                <div>
                  {item.subItem && (
                    <>{item.expanded ? <ExpandLess /> : <ExpandMore />}</>
                  )}
                </div>
              </MenuItem>
            ) : (
              <>
                <MenuItem
                  component={!item.subItem && Link}
                  button
                  open={open}
                  key={item.path + item.key}
                  selected={pathnamePage.includes(item.key)}
                  onClick={() => handleMenuClick(item)}
                >
                  {item.icon}
                  {open && <span>{item.label}</span>}
                  <Box>
                    {item.subItem && (
                      <>{item.expanded ? <ExpandLess /> : <ExpandMore />}</>
                    )}
                  </Box>
                </MenuItem>
                <Collapse
                  id="collapse"
                  in={item.expanded && open}
                  timeout="auto"
                  unmountOnExit
                >
                  <ListContainer subitem open={open}>
                    {item?.subItem &&
                      item.subItem.map((subItem) => (
                        <SubItem
                          component={Link}
                          to={subItem.path}
                          button
                          key={subItem.key}
                          selected={pathnamePage.includes(subItem.path)}
                          open={open}
                        >
                          {subItem.icon}
                          <Label>{subItem.label}</Label>
                        </SubItem>
                      ))}
                  </ListContainer>
                </Collapse>
              </>
            )}
          </div>
        ))}
        <MenuItem
          button
          key={"sair"}
          open={open}
          onClick={() => setShowReportModal(true)}
        >
          <AssessmentOutlined />
          {open && <span>{"Relatórios"}</span>}
        </MenuItem>
        <MenuItem
          button
          key={"sair"}
          open={open}
          onClick={() => {dispatch(Creators.signOut()); localStorage.clear()}}
        >
          <ExitToAppTwoTone />
          {open && <span>{"Sair"}</span>}
        </MenuItem>
      </ListContainer>
    </Drawer>
    </>
  );
}

export { Menu };
