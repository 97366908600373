/**
 * @typedef {{
 *  id: string;
 *  CNPJ: string
 *  name: string;
 *  street: string;
 *  district: string;
 *  city: string;
 *  state: string;
 *  zipCode: string;
 *  phoneNumber: string;
 *  isActive: boolean;
 *  useOnInvoice: boolean;
 *  enotasAPIKey: string;
 *  enotasCompanyId: string;
 *  cieloMerchantId: string;
 *  cieloMerchantKey: string;
 *  createdAt: string;
 *  updatedAt: string;
 * }} Store
 *
 * @typedef {{
 *  isActive?: boolean;
 *  useOnInvoice?: boolean;
 *  order_field?: string;
 *  order?: 'ASC' | 'DESC';
 *  page?: number;
 *  limit?: number;
 * }} Filters
 *
 * @typedef {import('../services/requestService').Meta<unknown>} Meta
 *
 * @typedef {{
 *  data: Store[];
 *  meta: Meta;
 * }} Stores
 */

import Api from "config/api";
import { toast } from "react-toastify";

class StoreServices {
  constructor() {
    this.path = "stores";
  }

  /**
   * @private
   * @template T
   * @template R
   * @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'} method Request method
   * @param {URL|string} [url]
   * @param {T} [data] Request payload
   * @param {Record<string, any>} [headers] Request headers
   * @param {string} [path]
   * @returns {Promise<import("axios").AxiosResponse<R, any>>}
   */
  buildRequest(method, url = "", data, params, headers, path) {
    return Api.request({
      method,
      url: path || `${this.path}/${url}`,
      headers,
      data,
      params,
    });
  }

  /**
   * @param {Filter} [params]
   * @returns {Promise<Stores>}
   */
  async findAll(params = {}) {
    try {
      const { data } = await this.buildRequest(
        "GET",
        undefined,
        undefined,
        params
      );
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Não foi possível buscar as lojas"
      );
    }
  }

  /**
   * @param {string} id
   * @returns {Promise<Store>}
   */
  async findById(id) {
    try {
      const { data } = await this.buildRequest("GET", id);
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Não foi possível buscar esse registro.",
        { icon: true }
      );
      return {};
    }
  }

  /**
   * @param {Omit<Store, 'id' | 'createdAt' | 'updatedAt'>} payload
   * @returns {Promise<Store>}
   */
  async create(payload) {
    try {
      const { data } = await this.buildRequest("POST", "", payload);
      toast.success("Loja criada com sucesso!");
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Não foi possível criar a loja",
        { icon: true }
      );
    }
  }

  /**
   * @param {string} id UUID store
   * @param {Partial<Store>} payload
   * @returns {Promise<Store>}
   */
  async update(id, payload) {
    try {
      const { data } = await this.buildRequest("PATCH", id, payload);
      toast.success("Loja atualizada com sucesso!");
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Não foi possível atualizar a loja",
        { icon: true }
      );
    }
  }

  /**
   * @returns {Promise<Store | null>}
   */
  async findActive() {
    try {
      const { data } = await this.buildRequest("GET", "active");
      return data;
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "Não foi possível buscar esse registro.",
        { icon: true }
      );
      return {};
    }
  }
}

const storeServices = new StoreServices();

export { storeServices };
