import styled from 'styled-components';

export const Container = styled.div`
    .icon-error {
        margin-left: 5px;
        font-size: 18px;
        color: red;
    }

    label {
        font-family: var(--font-text-primary);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: var(--color-input-title-primary);
    }

    select {
        width: 100%;
        height: 48px;
        background: var(--color-white);
        border: ${(p) => p.error ? '2px solid #F35457' : '1px solid #CECECE'};
        border-radius: 4px;
        padding-left: 10px;
        font-family: var(--font-text-primary);;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        margin-top: 4px;
        margin-bottom: ${(p) => p.error && '5px'};
        color: var(--color-input-title-primary);
        cursor: ${(p) => (p.disabled ? "no-drop" : "pointer")};

        ::placeholder {
            color: var(--color-input-placeholder-primary);
        }

        ::-webkit-calendar-picker-indicator{
            margin-right: 10px;
        }
    }

    .span-error {
        color: #F35457;
        font-size: 16px;
    }
`;
