import axios from "axios";
import { signOut } from "store/sagas/auth";
import { store } from "../store/index";

const Api = axios.create({
  baseURL: 'https://talisma-hmg.teclat.dev/api',
  // baseURL: 'http://192.168.1.10:3333',
});

Api.interceptors.request.use((config) => {
  const state = store.getState();
  const { token } = state.auth;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

Api.interceptors.response.use((response) => {
  if (response.status < 200 || response.status > 299) {
    signOut();
  }

  return response;
});

export default Api;
