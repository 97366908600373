import React from "react";
import { Container } from "./styles";
import { FiAlertCircle } from "react-icons/fi";

const Select = ({
  label,
  listOption,
  offLabelSelect = false,
  textLabelSelect = "Selecionar",
  required = false,
  name,
  disabled,
  error,
  textErro = "Campo obrigatório!",
  success,
  ...rest
}) => {
  return (
    <Container error={error} success={success} disabled={disabled}>
      <label htmlFor={name || label}>
        {label}
        {required && "*"}
        {error && <FiAlertCircle className="icon-error" title="Campo obrigatório!" />}
      </label>
      <select id={name || label} disabled={disabled} {...rest}>
        {offLabelSelect ? null : <option value="">{textLabelSelect}</option>}
        {listOption && listOption.map((item) => (<option key={item.id} value={item.id}>{item.description || item.name || item.size}</option>))}
      </select>
      {error && <span className="span-error">{textErro}</span>}
    </Container>
  );
};

export default Select;
