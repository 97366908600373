import { lazy } from "react";

//PERFIL ------------------------------------------------------------
const Profile = lazy(() => import("./Profile"));
const UpdatePassword = lazy(() => import("./Profile/updatePassword"));

//PEDIDOS ------------------------------------------------------------
const ListOrders = lazy(() => import("./Orders/Order"));
const RegisterOrder = lazy(() => import("./Orders/Order/register"));
const FreightTable = lazy(() => import("./Orders/FreightTable"));
const RegisterFreight = lazy(() => import("./Orders/FreightTable/register"));
const FormPayment = lazy(() => import("./Orders/FormPayment"));
const RegisterPayment = lazy(() => import("./Orders/FormPayment/register"));
const Settings = lazy(() => import("./Orders/Settings"));
const RegisterStores = lazy(() => import("./Orders/Settings/Tabs/RegisterStores"));

//PRODUTOS ------------------------------------------------------------
const Products = lazy(() => import("./Products/Products"));
const NewProduct = lazy(() => import("./Products/Products/register"));
const EditProduct = lazy(() => import("./Products/Products/register"));
const ViewProduct = lazy(() => import("./Products/Products/register"));
const Promotions = lazy(() => import("./Products/Promotions"));
const RegisterPromotions = lazy(() => import("./Products/Promotions/register"));
const News = lazy(() => import("./Products/News"));
const Categories = lazy(() => import("./Products/Categories"));
const NewCategorie = lazy(() => import("./Products/Categories/register"));
const TypesProduct = lazy(() => import("./Products/TypesProduct"));
const NewTypeProduct = lazy(() => import("./Products/TypesProduct/register"));

//ESTOQUE ------------------------------------------------------------
const Stocks = lazy(() => import("./ProductStock/inquiry"));
const Purchases = lazy(() => import("./ProductStock/purchases"));
const ReleaseStock = lazy(() => import("./ProductStock/stockRelease"));
const NewReleaseStock = lazy(() => import("./ProductStock/registerReleaseStock"));

//CLIENTES ------------------------------------------------------------
const Clients = lazy(() => import("./Clients/Clients"));
const NewClient = lazy(() => import("./Clients/Clients/register"));
const TypesClient = lazy(() => import("./Clients/TypesCliente"));
const NewTypeClient = lazy(() => import("./Clients/TypesCliente/register"));

//CUPONS ------------------------------------------------------
const Cupons = lazy(() => import("./Cupons"));
const RegisterCupom = lazy(() => import("./Cupons/register"));

//COLABORADORES ------------------------------------------------------
const Colaboradores = lazy(() => import("./Collaborators"));
const AddColaboradores = lazy(() => import("./Collaborators/register"));

//ATENDIMENTO ------------------------------------------------------
const Faq = lazy(() => import("./Attendance/Faq"));
const RegisterQuestion = lazy(() => import("./Attendance/Faq/register"));
const ConfigWhatsapp = lazy(() => import("./Attendance/ConfigWhatsapp"));
const RegisterWhatsapp = lazy(() => import("./Attendance/ConfigWhatsapp/register"));
const Notifications = lazy(() => import("./Attendance/Notifications"));
const RegisterNotifications = lazy(() => import("./Attendance/Notifications/register"));

//PERMISSOES ------------------------------------------------------
const ConsultaPermissoes = lazy(() => import("./Permissions/AccessControl"));
const RegisterConsultaPermissoes = lazy(() => import("./Permissions/AccessControl/register"));
const Audit = lazy(() => import("./Permissions/Audit"));
const Resume = lazy(() => import("./Permissions/Audit/resume"))

export const listRoutes = [
  // Perfil -----------------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/perfil",
    component: Profile,
  },
  {
    exact: false,
    private: true,
    path: "/perfil/alterar-senha",
    component: UpdatePassword,
  },
  //----------------------------------------------- Fim Perfil --------


  // Pedidos -----------------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/pedidos/pedidos",
    component: ListOrders,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/pedidos/novo-pedidos",
    component: RegisterOrder,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/pedidos/view/:id",
    component: RegisterOrder,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/pedidos/editar/:id",
    component: RegisterOrder,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/produtos/view-produtos/:id",
    component: ViewProduct,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/tabela-de-fretes",
    component: FreightTable,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/tabela-de-fretes/cadastrar",
    component: RegisterFreight,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/tabela-de-fretes/view/:id",
    component: RegisterFreight,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/tabela-de-fretes/editar/:id",
    component: RegisterFreight,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/formas-de-pagamento",
    component: FormPayment,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/formas-de-pagamento/cadastrar",
    component: RegisterPayment,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/formas-de-pagamento/view/:id",
    component: RegisterPayment,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/formas-de-pagamento/editar/:id",
    component: RegisterPayment,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/configuracoes",
    component: Settings,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/loja/cadastrar",
    component: RegisterStores,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/loja/view/:id",
    component: RegisterStores,
  },
  {
    exact: false,
    private: true,
    path: "/pedidos/loja/editar/:id",
    component: RegisterStores,
  },
  //----------------------------------------------- Fim Pedidos --------

  // Produtos ----------------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/produtos",
    component: Products,
  },
  {
    exact: false,
    private: true,
    path: "/produtos/novo-produto",
    component: NewProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produtos/editar-produtos/:id",
    component: EditProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produtos/view-produtos/:id",
    component: ViewProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produto/promocoes",
    component: Promotions,
  },
  {
    exact: false,
    private: true,
    path: "/produto/promocoes/cadastrar",
    component: RegisterPromotions,
  },
  {
    exact: false,
    private: true,
    path: "/produto/promocoes/view/:id",
    component: RegisterPromotions,
  },
  {
    exact: false,
    private: true,
    path: "/produto/promocoes/editar/:id",
    component: RegisterPromotions,
  },
  {
    exact: false,
    private: true,
    path: "/produto/novidades",
    component: News,
  },
  {
    exact: false,
    private: true,
    path: "/produto/categorias",
    component: Categories,
  },
  {
    exact: false,
    private: true,
    path: "/produto/categorias/nova-categoria",
    component: NewCategorie,
  },
  {
    exact: false,
    private: true,
    path: "/produto/categorias/editar/:id",
    component: NewCategorie,
  },
  {
    exact: false,
    private: true,
    path: "/produto/categorias/view/:id",
    component: NewCategorie,
  },
  {
    exact: false,
    private: true,
    path: "/produto/tipos",
    component: TypesProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produto/tipos/novo-tipo",
    component: NewTypeProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produto/tipos/editar/:id",
    component: NewTypeProduct,
  },
  {
    exact: false,
    private: true,
    path: "/produto/tipos/view/:id",
    component: NewTypeProduct,
  },
  //---------------------------------------------- Fim Produtos --------

  // Estoque -----------------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/estoque",
    component: Stocks,
  },
  {
    exact: false,
    private: true,
    path: "/estoque/view/:id",
    component: Purchases,
  },
  {
    exact: false,
    private: true,
    path: "/lancamento-estoque",
    component: ReleaseStock,
  },
  {
    exact: false,
    private: true,
    path: "/lancamento-estoque/novo-lancamento",
    component: NewReleaseStock,
  },

  {
    exact: false,
    private: true,
    path: "/lancamento-estoque/editar/:id",
    component: NewReleaseStock,
  },
  {
    exact: false,
    private: true,
    path: "/lancamento-estoque/view/:id",
    component: NewReleaseStock,
  },
  // {
  //   exact: false,
  //   private: true,
  //   path: "/produtos/view-produtos/:id",
  //   component: ViewProduct,
  // },
  //----------------------------------------------- Fim Estoque --------

  // Clientes ----------------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/usuarios",
    component: Clients,
  },
  {
    exact: false,
    private: true,
    path: "/usuarios/novo-usuario",
    component: NewClient,
  },
  {
    exact: false,
    private: true,
    path: "/usuarios/editar/:id",
    component: NewClient,
  },
  {
    exact: false,
    private: true,
    path: "/usuarios/view/:id",
    component: NewClient,
  },
  {
    exact: false,
    private: true,
    path: "/tipos-usuarios",
    component: TypesClient,
  },
  {
    exact: false,
    private: true,
    path: "/tipos-usuarios/novo-tipo",
    component: NewTypeClient,
  },
  {
    exact: false,
    private: true,
    path: "/tipos-usuarios/editar/:id",
    component: NewTypeClient,
  },
  {
    exact: false,
    private: true,
    path: "/tipos-usuarios/view/:id",
    component: NewTypeClient,
  },
  //---------------------------------------------- Fim Clientes --------

  // Cupons -----------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/cupons",
    component: Cupons,
  },
  {
    exact: false,
    private: true,
    path: "/cupons/cadastrar",
    component: RegisterCupom,
  },
  {
    exact: false,
    private: true,
    path: "/cupons/view/:id",
    component: RegisterCupom,
  },
  {
    exact: false,
    private: true,
    path: "/cupons/editar/:id",
    component: RegisterCupom,
  },
  //----------------------------------------- Fim Cupons --------

  // Colaboradores -----------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/colaboradores",
    component: Colaboradores,
  },
  {
    exact: false,
    private: true,
    path: "/colaboradores/novo-colaborador",
    component: AddColaboradores,
  },
  {
    exact: false,
    private: true,
    path: "/colaboradores/view/:id",
    component: AddColaboradores,
  },
  {
    exact: false,
    private: true,
    path: "/colaboradores/editar/:id",
    component: AddColaboradores,
  },
  //----------------------------------------- Fim Colaboradores --------

  // Atendimento -----------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/atendimento/faq",
    component: Faq,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/faq/cadastrar",
    component: RegisterQuestion,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/faq/view/:id",
    component: RegisterQuestion,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/faq/editar/:id",
    component: RegisterQuestion,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/config-whatsapp/cadastrar",
    component: RegisterWhatsapp,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/config-whatsapp/view/:id",
    component: RegisterWhatsapp,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/config-whatsapp/editar/:id",
    component: RegisterWhatsapp,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/config-whatsapp",
    component: ConfigWhatsapp,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/notificacoes",
    component: Notifications,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/notificacoes/registrar",
    component: RegisterNotifications,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/notificacoes/view/:id",
    component: RegisterNotifications,
  },
  {
    exact: false,
    private: true,
    path: "/atendimento/notificacoes/editar/:id",
    component: RegisterNotifications,
  },
  //----------------------------------------- Fim Atendimento --------

  // Permissões -----------------------------------------------------
  {
    exact: false,
    private: true,
    path: "/permissoes/controle-de-acesso",
    component: ConsultaPermissoes,
  },
  {
    exact: false,
    private: true,
    path: "/permissoes/controle-de-acesso/cadastrar",
    component: RegisterConsultaPermissoes,
  },
  {
    exact: false,
    private: true,
    path: "/permissoes/controle-de-acesso/view/:id",
    component: RegisterConsultaPermissoes,
  },
  {
    exact: false,
    private: true,
    path: "/permissoes/controle-de-acesso/editar/:id",
    component: RegisterConsultaPermissoes,
  },
  {
    exact: false,
    private: true,
    path: "/permissoes/auditorias",
    component: Audit,
  },
  {
    exact: false,
    private: true,
    path: "/permissoes/auditorias/view/:id",
    component: Resume,
  },
];
