import React, { useRef, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, AppBar, Toolbar, TextField, Box } from "@material-ui/core";
import TalismaLogo from "assets/images/logo.png";
import usersService from "services/usersService";

import {
  Container,
  ContentToolbar,
  Divider,
  FormContainer,
  Logo,
  LogoContainer,
} from "./style";
import Button from "components/Button";

function RecoverPassword() {
  const formEl = useRef(null);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  function handleSubmit() {
    let data ={
      email: email 
    }
    if (email) {
      setErrorEmail(false);

      usersService.forgotPassword(data)
      .then(() => {
        setEmail("");
      })
    } else {
      setErrorEmail(true);
    }
  }

  return (
    <>
      <ContentToolbar>
        <AppBar position="fixed" style={{ background: "primary" }}>
          <Toolbar>
            <Typography variant="h6"></Typography>
          </Toolbar>
          <Divider />
        </AppBar>
      </ContentToolbar>
      <Container>
        <LogoContainer>
          <Logo src={TalismaLogo} />
        </LogoContainer>
        <FormContainer ref={formEl} noValidate autoComplete={"off"}>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginBottom: "20px" }}
            color="textSecondary"
          >
            Escreva seu e-mail para solicitar nova senha
          </Typography>
          <Box mb={2} style={{ marginBottom: 20 }}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Email
            </Typography>
            <TextField
              name="email"
              variant="outlined"
              placeholder="exemplo@exemplo.com"
              style={{ marginTop: "5px" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={errorEmail}
              helperText={errorEmail ? "Campo obrigatório!" : ""}
              fullWidth
            />
          </Box>

          <Button
            title={"Enviar"}
            color={"primary"}
            width={90}
            handleOnClick={handleSubmit}
          />
          <div className="collum-center-width">
            <Typography
              variant="h5"
              style={{ marginTop: "30px" }}
              color="textSecondary"
            >
              Lembrou sua senha? <Link to="/" className="link-login" >Login</Link>
            </Typography>
          </div>
        </FormContainer>
      </Container>
    </>
  );
}

export default withRouter(RecoverPassword);
