import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import {
  Typography,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";

import { Creators } from "store/ducks/auth";
import TalismaLogo from "assets/images/logo.png";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import Button from "components/Button";
import { useTheme } from "styled-components";

import {
  Container,
  ContentToolbar,
  Divider,
  FormContainer,
  Logo,
  LogoContainer,
  Footer,
  MainContainer,
} from "./style";
import { storeServices } from "services/storesService";
import { mCEP, mTel } from "services/masksService";

function Login() {
  const theme = useTheme();
  const formEl = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [store, setStore] = useState({
    address: "R BARAO DO RIO BRANCO, 1120 - Centro - Fortaleza - CE 60025-060",
    cnpj: "10.649.482/0001-69",
    phone: "(85) 3221-2667",
    name: "MARCOS TIAGO PEREIRA TORRES BIJUTERIAS",
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string("E-mail")
        .email("Digite um endereço de e-mail válido.")
        .required("Campo obrigatório"),
      password: yup.string("Senha").required("Campo obrigatório"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      console.log('deploy v1.0')

      dispatch(Creators.signInRequest(values.email, values.password));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    },
  });

  async function getCurrentStore() {
    const store = await storeServices.findActive();
    if (store) {
      const zipCode = await mCEP(store.zipCode);
      const phoneNumber = await mTel(store.phoneNumber);
      setStore({
        address:
          `${store.street} - ${store.district} - ${store.city} - ${store.state} ${zipCode}`.toUpperCase(),
        cnpj: store.CNPJ,
        name: store.name,
        phone: phoneNumber,
      });
    }
  }

  useEffect(() => {
    getCurrentStore();
  }, []);

  return (
    <MainContainer>
      <ContentToolbar>
        <AppBar style={{ background: "primary", position: "relative" }}>
          <Toolbar>
            <Typography variant="h6"></Typography>
          </Toolbar>
          <Divider />
        </AppBar>
      </ContentToolbar>
      <Container>
        <LogoContainer>
          <Logo src={TalismaLogo} />
        </LogoContainer>
        <FormContainer ref={formEl} noValidate autoComplete={"off"}>
          <Box mb={2}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Login
            </Typography>
            <TextField
              name="email"
              variant="outlined"
              placeholder="exemplo@exemplo.com"
              style={{ marginTop: "5px" }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
          </Box>
          <Box mb={3}>
            <Typography
              variant="h5"
              style={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Senha
            </Typography>
            <TextField
              name="password"
              variant="outlined"
              placeholder="Senha"
              style={{ marginTop: "5px" }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              type={showPassword ? "text" : "password"}
              onKeyDown={(e) =>
                e.key === "Enter" ? formik.handleSubmit() : {}
              }
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <VisibilityOutlined color="primary" />
                      ) : (
                        <VisibilityOffOutlined color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            title={"Entrar"}
            isLoading={loading}
            color={"primary"}
            width={90}
            handleOnClick={formik.handleSubmit}
          />

          <div className="collum-center-width">
            <Typography
              variant="h5"
              style={{ marginTop: "20px" }}
              color="textSecondary"
            >
              Esqueceu a senha?{" "}
              <Link to="/recover-password" className="link-login">
                Solicitar
              </Link>
            </Typography>
          </div>
        </FormContainer>
      </Container>
      <Footer>
        <Typography style={{ color: theme.palette.common.white }}>
          {store.address} - Telefone: {store.phone}
        </Typography>
        <Typography style={{ color: theme.palette.common.white }}>
          {store.name} | CNPJ: {store.cnpj}
        </Typography>
      </Footer>
    </MainContainer>
  );
}

export default withRouter(Login);
