import React from "react";
import { Router, Switch } from "react-router-dom";

import RouteComponent from "./Route";
import history from "config/history";

import Login from "pages/Login";
import Recover from "pages/RecoverPassword";
import NewPassword from "pages/NewPassword";
import Home from "pages/Home";

import Layout from "components/Layout/index";

export default function Route() {
  return (
    <Router history={history}>
      <Switch>
        <RouteComponent exact path="/" component={Login} isPrivate={false} />
        <RouteComponent exact path="/recover-password" component={Recover} isPrivate={false} />
        <RouteComponent exact path="/new-password*" component={NewPassword} isPrivate={false} />
        <RouteComponent exact path="/home" component={Home} isPrivate={true} />
        <Layout />
      </Switch>
    </Router>
  );
}
