function getDate(date) {
  date = new Date(date);
  date = date.toJSON();
  date = date.split("T", 1)[0];
  return date;
}

function formattedDate(date) {
  date = new Date(date);
  date = date.toJSON();
  date = date.split("T", 1)[0];
  date = date.split("-").reverse().join("/");
  return date;
}

/**
 * https://stackoverflow.com/a/196991
 * @param {string} value Palavra a ser convertida
 * @returns value capitalizada
 */
function toTitleCase(value) {
  return value.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const forceDownload = (uri) => {
  const link = document.createElement('a');
  link.href = uri;
  link.rel = 'noopener noreferrer';
  link.click();
};

export { getDate, formattedDate, toTitleCase, forceDownload };
