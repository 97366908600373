import Api from "config/api";
import { toast } from "react-toastify";
import { store } from "store";
import { forceDownload } from "utils";

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 *  email: string;
 *  birthday: string;
 *  document: string;
 *  otherDocument: string | null;
 *  phone: string;
 *  whatsapp: string;
 *  status: 0 | 1 |2
 *  buyer: boolean;
 *  pushToken: string | null
 *  createdAt: string;
 *  updatedAt: string;
 *  deletedAt: string | null;
 * }} User
 */

export async function findAllUsers(
  page,
  search = "",
  userType = "",
  limit = 10
) {
  try {
    const { data } = await Api.get(
      `/users?page=${page}${search && `&search=${search}`}${
        userType && `&userType=${userType}`
      }${limit && `&limit=${limit}`}`
    );

    return data;
  } catch (err) {
    toast.error("Erro ao carregar os dados");

    throw err;
  }
}

export async function findAllCollaborator(page, search = "", exclude = "") {
  try {
    const { data } = await Api.get(
      `/users?page=${page}${search && `&search=${search}`}${exclude && `&exclude=${exclude}`}&isCollaborator=true`
    );

    return data;
  } catch (err) {
    toast.error("Erro ao carregar os dados");

    throw err;
  }
}

export async function getOneUsers(id) {
  try {
    const { data } = await Api.get(`/users/${id}`);

    return data;
  } catch (err) {
    if (err.response.status === 401) {
      toast.error("Necessário fazer login novamente");
    } else {
      toast.error("Erro ao carregar os dados");
    }

    throw err;
  }
}

export async function createAllUsers(user) {
  try {
    const { data } = await Api.post("/users", user);

    toast.success("Cadastrado com sucesso!");

    return data;
  } catch (err) {
    toast.error(
      err.response.data.message || "Não foi possível carregar os dados"
    );

    throw err;
  }
}

export async function updateUsers(id, user) {
  try {
    const { data } = await Api.patch(`/users/${id}`, user);

    toast.success("Alterado com sucesso!", { icon: true });

    return data;
  } catch (err) {
    toast.error("Erro ao carregar os dados", { icon: true });

    throw err;
  }
}

export async function forgotPassword(email) {
  try {
    const { data } = await Api.post(`/auth/forgot-password`, email);
    toast.success("Enviado com sucesso!", { icon: true });
    toast.info("Verifique sua caixa de e-mail.", { icon: true });

    return data;
  } catch (err) {
    toast.error("Erro ao carregar os dados", { icon: true });

    throw err;
  }
}

export async function changePassword(password, token) {
  try {
    const { data } = await Api.patch(`/users/change-password`, password, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return data;
  } catch (err) {
    toast.error("Erro ao cadastrar nova senha.", { icon: true });

    throw err;
  }
}

/**
 * @param {string} userId Id de usuário
 * @param {string} password Nova senha
 * @param {string} confirmPassword Confirme sua nova senha
 */
async function updateUserPassword(userId, password, confirmPassword) {
  try {
    await Api.patch(`/users/update-password/${userId}`, {
      password,
      confirmPassword,
    });
  } catch (err) {
    toast.error(err?.response?.data?.error || 'Não foi possível alterar a senha')
  }
}

export async function findAllUsersTypes(page, search = "") {
  try {
    const { data } = await Api.get(
      `/userTypes?page=${page}${search && `&search=${search}`}`
    );

    return data;
  } catch (err) {
    toast.error("Erro ao carregar os dados", { icon: true });

    throw err;
  }
}

export async function getOneUsersTypes(id) {
  try {
    const { data } = await Api.get(`/userTypes/${id}`);

    return data;
  } catch (err) {
    toast.error(
      err.response.data.message || "Não foi possível carregar os dados"
    );

    throw err;
  }
}

export async function createUsersTypes(data) {
  try {
    const { response } = await Api.post("/userTypes", data);

    toast.success("Cadastrado com sucesso!", { icon: true });

    return response;
  } catch (err) {
    toast.error(
      err.response.data.message || "Não foi possível carregar os dados"
    );

    throw err;
  }
}

export async function updateUsersTypes(id, data) {
  try {
    const { response } = await Api.patch(`/userTypes/${id}`, data);

    toast.success("Alterado com sucesso!", { icon: true });

    return response;
  } catch (err) {
    toast.error("Erro ao carregar os dados", { icon: true });

    throw err;
  }
}

export async function updatePassword(data) {
  try {
    const { response } = await Api.patch(`/users/update-password`, data);

    toast.success("Senha alterada com sucesso!", { icon: true });

    return response;
  } catch (err) {
    toast.error(err?.response?.data?.message, { icon: true });

    throw err;
  }
}

export async function generateNewestUser(startDate, endDate) {
  const state = store.getState();
  const { token } = state.auth;

  const uri = Api.getUri({
    url: '/users/newest-users',
    params: { startDate, endDate, token },
  });

  return forceDownload(uri)
}

export async function generateWithoutPurchases() {
  const state = store.getState();
  const { token } = state.auth;

  const uri = Api.getUri({
    url: '/users/without-purchases',
    params: { token },
  });

  return forceDownload(uri)
}

export async function generateWithPurchasesByDateRange(startDate, endDate) {
  const state = store.getState();
  const { token } = state.auth;

  const uri = Api.getUri({
    url: '/users/users-with-purchases',
    params: { startDate, endDate, token },
  });

  return forceDownload(uri)
}

export async function generateWithNoPurchasesByDateRange(startDate, endDate) {
  const state = store.getState();
  const { token } = state.auth;

  const uri = Api.getUri({
    url: '/users/users-with-no-purchases',
    params: { startDate, endDate, token },
  });

  return forceDownload(uri)
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  findAllUsers,
  findAllCollaborator,
  getOneUsers,
  createAllUsers,
  updateUsers,
  forgotPassword,
  changePassword,
  findAllUsersTypes,
  getOneUsersTypes,
  createUsersTypes,
  updateUsersTypes,
  updatePassword,
  updateUserPassword,
  generateNewestUser,
  generateWithoutPurchases,
  generateWithPurchasesByDateRange,
  generateWithNoPurchasesByDateRange,
};
