import styled from "styled-components";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  alignSelf: 'stretch',
}));

export const LogoContainer = styled("div")(({ theme }) => ({
  marginBottom: "24px",
}));

export const Logo = styled("img")(({ theme }) => ({
  width: "11rem",
}));

export const ContentToolbar = styled("div")(({ theme }) => ({
  marginBottom: 15,
}));

export const FormContainer = styled("form")(({ theme }) => ({
  minWidth: "22.5rem",
  filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16))",
  backgroundColor: theme.palette.background.default,
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.16)",
  borderRadius: "30px",
  padding: "3rem 2rem 3.5rem",
  paddingBottom: "2rem",
  alignItems: 'center',

  ".MuiInputBase-root": {
    color: theme.palette.grey[200],
    background: theme.palette.secondary.contrastText,
    border: "none",
  },

  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.common.white,
  },

}));

export const Divider = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: "0.8rem",
}));

export const Footer = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  width: 100vw;
  padding: 10px 0px;
  text-align: center;
  margin-top: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`;
